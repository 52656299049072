import { createStore } from 'vuex'
import axios from 'axios'

const store = createStore({
  state: {
    email: '1',
    password: '2'
  },
  mutations: {
    updateEmailPassword(state, payload) {
        state.email = payload.email
        state.password = payload.password
      }
  },
  actions: {
    login(context, payload) {
        axios.post('/api/login', {
          email: payload.email,
          password: payload.password
        })
        .then(response => {
          context.commit('updateEmailPassword', {
            email: payload.email,
            password: payload.password
          });
          console.log(response);
        })
      }
  },
  getters: {
    // Define the getters for your store here
  }
})

export default store