// import axios from 'axios';

export async function isAuthenticated() {
    try {
      // Check if the loginSuccess value is 'true' in localStorage
      const loginSuccess = localStorage.getItem('loginSuccess');
      if (loginSuccess === 'true') {
        return true;
      } else {
        return null;
      }
    } catch (error) {
        console.log(error);
      return false;
    }
  }
  
