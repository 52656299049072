import { createWebHistory, createRouter } from "vue-router";
// import ProtectedPage from './components/ProtectedPage';
import { isAuthenticated } from './auth';

const requiresAuth = (to, from, next) => {
  // Call the isAuthenticated function
  isAuthenticated().then(authenticated => {
    if (authenticated) {
      // The user is authenticated, allow the route to be entered
      next();
    } else {
      // The user is not authenticated, redirect to the login page
      next({ path: '/login' });
    }
  });
};

const routes =  [
  // {
  //   path: "/",
  //   name: "list-component",
  //   component: () => import("./components/list-component")
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("./components/login/Login")
  },
  {
    path: '/',
    beforeEnter: requiresAuth,
    component: () => import("./components/list-component")
  },  
  {
    path: "/shootings",
    name: "shootings",
    beforeEnter: requiresAuth,
    component: () => import("./components/shootings/ShootingsList")
  },
  {
    path: "/shootings/:id",
    name: "shooting-details",
    beforeEnter: requiresAuth,
    component: () => import("./components/shootings/Shooting")
  },
  {
    path: "/addShooting",
    name: "addShooting",
    beforeEnter: requiresAuth,
    component: () => import("./components/shootings/AddShooting")
  },
  {
    path: "/castings",
    name: "castings",
    beforeEnter: requiresAuth,
    component: () => import("./components/castings/CastingsList")
  },
  {
    path: "/castings/:id",
    name: "casting-details",
    beforeEnter: requiresAuth,
    component: () => import("./components/castings/Casting")
  },
  {
    path: "/addCasting",
    name: "addCasting",
    beforeEnter: requiresAuth,
    component: () => import("./components/castings/AddCasting")
  },
  
  {
    path: "/users",
    name: "users",
    beforeEnter: requiresAuth,
    component: () => import("./components/users/UsersList")
  },
  {
    path: "/users/:id",
    name: "user-details",
    beforeEnter: requiresAuth,
    component: () => import("./components/users/User")
  },
  {
    path: "/addUser",
    name: "addUser",
    beforeEnter: requiresAuth,
    component: () => import("./components/users/AddUser")
  },
  {
    path: "/models",
    name: "models",
    beforeEnter: requiresAuth,
    component: () => import("./components/models/ModelsList")
  },
  {
    path: "/models/:id",
    name: "model-details",
    beforeEnter: requiresAuth,
    component: () => import("./components/models/Model")
  },
  {
    path: "/addModel",
    name: "addModel",
    beforeEnter: requiresAuth,
    component: () => import("./components/models/AddModel")
  },
  {
    path: "/agencies",
    name: "agencies",
    beforeEnter: requiresAuth,
    component: () => import("./components/agencies/AgenciesList")
  },
  {
    path: "/agencies/:id",
    name: "agency-details",
    beforeEnter: requiresAuth,
    component: () => import("./components/agencies/Agency")
  },
  {
    path: "/addAgency",
    name: "addAgency",
    beforeEnter: requiresAuth,
    component: () => import("./components/agencies/AddAgency")
  },
  {
    path: "/customers",
    name: "customers",
    beforeEnter: requiresAuth,
    component: () => import("./components/customers/CustomersList")
  },
  {
    path: "/customers/:id",
    name: "customer-details",
    beforeEnter: requiresAuth,
    component: () => import("./components/customers/Customer")
  },
  {
    path: "/addCustomer",
    name: "addCustomer",
    beforeEnter: requiresAuth,
    component: () => import("./components/customers/AddCustomer")
  },
  {
    path: "/addModelManually",
    name: "addModelManually",
    beforeEnter: requiresAuth,
    component: () => import("./components/models/AddModelManually")
  },
  {
    path: "/calendar",
    name: "calendar",
    beforeEnter: requiresAuth,
    component: () => import("./components/EventCalendar")
  },
  {
    path: "/crawlerone",
    name: "crawlerone",
    beforeEnter: requiresAuth,
    component: () => import("./components/crawler/CrawlerOne")
  },
  {
    path: "/crawlermodelwerk",
    name: "crawlermodelwerk",
    beforeEnter: requiresAuth,
    component: () => import("./components/crawler/CrawlerModelwerk")
  },
  {
    path: "/crawleramanagement",
    name: "crawleramanagement",
    beforeEnter: requiresAuth,
    component: () => import("./components/crawler/CrawlerAmanagement")
  },
  {
    path: "/crawlerm4",
    name: "crawlerm4",
    beforeEnter: requiresAuth,
    component: () => import("./components/crawler/CrawlerM4")
  },
  {
    path: "/maincrawler",
    name: "maincrawler",
    beforeEnter: requiresAuth,
    component: () => import("./components/crawler/MainCrawler")
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// localStorage.eminmail = "emin.tavli@mbs-team.de";
// localStorage.eminpw = "dasisteintestpasswort";
// localStorage.loggedIn = 1;

// if (navigator.userAgent.indexOf('Firefox') !== -1) {
//   localStorage.setItem('userrole', 'mbs');
// }

// router.afterEach(async () => {
  
//   if ((window.location.href == 'login')) {
//     console.log("yes");
//     document.getElementsByClassName("vsm--link_level-1").style.display = 'flex';
//   } else {
//     if(localStorage.getItem("loggedIn") == 0) {
//       console.log("none2");
//       window.location.href = 'login';
//       document.getElementsByClassName("vsm--link_level-1").style.display = 'none !important';
//     }
//   }
//   });

export default router;
