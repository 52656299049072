import { createApp, Vue } from 'vue'
import App from './App.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import router from './router'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import acl from './acl'; // import the instance of the defined ACL
import store from './store'

/* Font Awesome */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faHeart, faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)
library.add(faHeart)
library.add(fas);

createApp(App)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(acl)
  .use(store) // use the store
  .mount('#app')

// change the mount code to this:
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')