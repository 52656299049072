<template>
<link href="http://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css" rel="stylesheet"/>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark">
      <div class="navbar-nav mr-auto" style="margin: 0 auto;">
        <li class="nav-item">
          <!-- <router-link to="/castings" class="nav-link">Castings</router-link> -->
          <div class="col-md-8">
            <div class="input-group mb-3" style="width: 1080px">
              <input style="margin-top: 5px; border-radius: 0.25rem; background-color: #d9d9d9; color: #fff !important;" type="text" class="form-control" placeholder="Search by title"
                v-model="title"/>
              <div class="input-group-append">
                <font-awesome-icon class="navbar-icon" icon="search" @click="searchTitle" />
                <font-awesome-icon class="navbar-icon" icon="lightbulb" @click="toggleMode" />
                
                <a class="aspect-button" v-if="loggedIn" style="margin: 15px 20px; font-size: 14px; cursor: pointer;" @click="logout">Logout</a>
                <a class="aspect-button" v-if="!loggedIn" style="margin: 15px 20px; font-size: 14px; cursor: pointer;" @click="login">Login</a>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <h1>{{ menu.title }}</h1>
        </li>
      </div>
      <!--<div >Eingeloggt als <a href="/users/1" style="color: #B185BA;"><strong>Emin Tavli</strong></a></div>-->
    </nav>
    <div class="container mt-3">
      <router-view />
    </div>
  </div>
  <sidebar-menu :menu="filteredMenu" />
  <!-- <div class="wrapper">
    <div class="model" v-for="(modelName, index) in scrapedInfo" :key="index">
      <span class="modelname" v-text="modelName.externalid"></span><span class="modelname" v-text="modelName.name"></span><span class="modelname" v-text="modelName.link"></span><a :href="'/models/' + modelName.id"><img class="modelimage" :src="modelName.image"></a>
    </div>
  </div> -->
  <!-- <a class="list-link" :href="'/castings/' + casting.id"></a> -->
</template>

<script>

// const axios = require('axios');
// const cheerio = require('cheerio');

import { SidebarMenu } from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
//import { SidebarPlugin } from 'bootstrap-vue'

export default {
  name: "app",
  components: {
    SidebarMenu
  },

  data() {
      return {
        isDark: false,
        menu: [
          {
            header: 'MODELTOOL',
            hiddenOnCollapse: true
          },
          {
            href: '/',
            title: '‎‎Dashboard',
            icon: 'fa fa-home'
          },
          {
            href: '/shootings',
            title: 'Shootings',
            // icon: 'fa fa-circle'
            /*
            child: [
              {
                href: '/charts/sublink',
                title: 'Sub Link'
              }
            ]
            */
          },
          {
            href: '/castings',
            title: 'Castings',
            // icon: 'fa fa-circle'
            // icon: 'fa fa-chart-area',
            /*
            child: [
              {
                href: '/charts/sublink',
                title: 'Sub Link'
              }
            ]
            */
          },
          {
            href: '/models',
            title: 'Models',
            // icon: 'fa fa-circle'
          },
          {
            href: '/agencies',
            title: 'Agenturen',
            // icon: 'fa fa-circle'
          },
          {
            href: '/customers',
            title: 'Kunden',
            // icon: 'fa fa-circle'
          }
        ],
        scrapedInfo: [],
      }
    },
    computed: {
      loggedIn() {
        return localStorage.getItem('loginSuccess') === 'true'
      },
      filteredMenu() {
        if (this.currentUserRole === 'admin') {
          // Return all menu items if loggedIn is true
          return this.menu;
        } else {
          // Otherwise, filter the menu items to display only the 'castings' item
          return this.menu.filter((item) => item.href === '/castings');
        }
      },
      currentUserRole() {
        return localStorage.getItem('role');
      }
    },
    methods:{
      toggleMode() {
        this.isDark = !this.isDark;
        localStorage.setItem('isDark', JSON.stringify(this.isDark));
        this.updateStyles();
      },
      updateStyles() {
        const root = document.documentElement;
        root.style.setProperty('--background-secondary', this.isDark ? '#495057' : '#F5F5F5');
        root.style.setProperty('--background-color', this.isDark ? '#2e3338' : '#d9d9d9');
        root.style.setProperty('--background-gradient-default', this.isDark ? 'rgb(255,206,139)' : 'rgb(255,206,139)');
        root.style.setProperty('--background-gradient', this.isDark ? 'linear-gradient(180deg, rgba(229, 148, 98, 1) 0%, rgba(171, 121, 135, 1) 50%, rgba(168, 115, 229, 1) 100%)' : 'linear-gradient(180deg, rgba(255,206,139,1) 0%, rgba(245,187,194,1) 50%, rgba(238,181,255,1) 100%)');
        root.style.setProperty('--text-color', this.isDark ? 'white' : '#495057');
        root.style.setProperty('--border-color', this.isDark ? 'rgba(255,255,255,.1' : 'rgba(0,0,0,.1)');
        root.style.setProperty('--button-color', this.isDark ? 'rgba(17, 74, 0, 1)' : '#B185BA');
      },
      getMetroModels() {

      },
      logout() {
        // Clear the login status from localStorage
        localStorage.removeItem('loginSuccess');
        localStorage.removeItem('role');
        this.loginSuccess = false;

        // Redirect to the login page
        this.$router.push({ path: '/login' });

        location.reload();
        
      },
      login() {
        // Redirect to the login page
        this.$router.push({ path: '/login' });
      }
    },
    created() {
      this.getMetroModels();
    },
    mounted() {
      this.isDark = JSON.parse(localStorage.getItem('isDark')) || false;
      this.updateStyles();
      

      if(this.loggedIn) {
        console.log("Logged In");

      } else {
        console.log("Logged Out");
      }
    },
};
</script>


<style lang="scss">
@import "/src/styles/quasar.variables.sass";

body {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.navbar {
  background-color: var(--background-secondary) !important;
  color: var(--text-color) !important;
}


.list-group {
    background-color: #fff;
    border-radius: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 60px !important;
}

.list-group li {
    width: 90%;
    margin: 5px auto;
    background-color: var(--background-color) !important;
    border: none;
    border-radius: inherit;
}

.list-group li:nth-child(2n), .list-group, tbody, thead, .fc-header-toolbar {
    background-color:  var(--background-secondary) !important;
}

.upper-li {
    background-color:  var(--background-color) !important;
    border: none !important;
}

.list-group .active {
    color:#B185BA !important;
}

.navbar, .edit-icon, .list-link span, .container a{
  color: var(--text-color) !important;
}

#search-icon, .card-header {
  color: var(--background-color) !important;
}

.v-sidebar-menu {
  // background: var(--background-gradient-default) !important;
  background: var(--background-gradient);
}

hr {
  border-color: var(--border-color) !important;
}

// button {
//   background-color: var(--button-color) !important;
// }
</style>